import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import { useSelector } from "react-redux";
import { selectSendMessageIsOpen } from "../features/mailSlice";
import { selectUser } from "../features/userSlice";
import { useState } from "react";
import { useEffect } from "react";
import { db } from "../firebase";
// import SendMail from '../components/SendMail/SendMail';

export default function MainLayout() {
  const sendMessageIsOpen = useSelector(selectSendMessageIsOpen);
  const user = useSelector(selectUser);
  const [emails, setEmails] = useState([]);
  useEffect(() => {
    db.collection("emails")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) =>
        setEmails(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
  }, []);

  return (
    <div className="app">
      <Header />
      <div className="app-body">
        <Sidebar emails={emails} />
        <Outlet />
        {/* {sendMessageIsOpen && <SendMail />} */}
      </div>
    </div>
  );
}
