import { Formik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import Logo from "../../assets/LOGO.png";
import { UseAuthContextHook } from "../../context/AuthContext";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom/dist";
import ButtonLoader from "../../Utils/ButtonLoader";
import { API_URLS } from "../../Utils/API_URLS";
import axios from "axios";
import { useLocation } from "react-router-dom";

export default function ConfirmPage() {
  const { handleLogin } = UseAuthContextHook();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState("");
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const handleForgot = async (values) => {
    setLoader(true);
    // console.log(values);
    let body = {
      password: values?.username,
    };
    await axios
      .post(`${API_URLS.resetPassword}?token=${token}`, body, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "*/",
        },
      })
      .then((res) => {
        setLoader(false);
        toast.success("New Password Create Successfully");
        navigate("/");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err?.response?.data?.message);
      });
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    // console.log(token, "token");
    if (token) {
      // Do something with the token (e.g., send it to a backend API for verification)
      setToken(token);
    }
    // Token is missing, handle the error or redirect to an appropriate page
    // Redirect to the forgot password page
  }, []);
  return (
    <div className="sign-up-container d-flex justify-content-center align-items-center">
      <div className="form-container py-5 px-4 d-flex">
        <Formik
          initialValues={{ username: "" }}
          validationSchema={Yup.object({
            username: Yup.string().required("Password is required"),
          })}
          onSubmit={(values) => {
            handleForgot(values);
          }}
        >
          {(formik) => (
            <form
              onSubmit={formik.handleSubmit}
              className="d-flex flex-column gap-3 step w-100"
            >
              <div className="form-header d-flex flex-column align-items-center justify-content-center gap-3">
                <img className="m-auto" width="60%" src={Logo} alt="Logo" />
                <h5>Login to your account</h5>
              </div>

              <div className="form-body d-flex flex-column gap-3">
                <div className="d-flex flex-column">
                  <label htmlFor="username">Create Your New Password</label>
                  <input
                    name="username"
                    type="text"
                    placeholder="Enter Your New Udenz Password"
                    onChange={formik.handleChange}
                    {...formik.getFieldProps("username")}
                  />
                  {formik.touched.username && formik.errors.username ? (
                    <div className="field-error">{formik.errors.username}</div>
                  ) : null}
                </div>
              </div>

              <div className="form-footer mt-3">
                <div className="d-flex justify-content-center">
                  <button disabled={loader} type="submit">
                    {loader ? <ButtonLoader /> : "Submit"}
                  </button>
                </div>
              </div>
            </form>
            
          )}
        </Formik>
      </div>
    </div>
  );
}