// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebarOption {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 10px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    cursor: pointer;
    color: #818181;
}


.sidebarOption:hover,
.sidebarOption--active {
    background-color: rgba(9, 110, 51, 0.2);
}

.sidebarOption:hover>p,
.sidebarOption:hover>h3,
.sidebarOption--active>p,
.sidebarOption--active>h3 {
    color: rgb(9, 110, 51);
    /* color:#c04b37; */
    font-weight: 800 !important;
}

.sidebarOption>h3 {
    flex: 1 1;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
}

.sidebarOption>p {
    display: none;
    font-weight: 300;
}

.sidebarOption--active>p {
    display: inline !important;
}

.sidebarOption:hover>p {
    display: inline;
}

.sidebarOption>.MuiSvgIcon-root {
    padding: 5px;
}

@media only screen and (max-width: 500px) {
    .sidebarOption>.MuiSvgIcon-root {
        display: none;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/SidebarOption.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,6BAA6B;IAC7B,gCAAgC;IAChC,eAAe;IACf,cAAc;AAClB;;;AAGA;;IAEI,uCAAuC;AAC3C;;AAEA;;;;IAII,sBAAsB;IACtB,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,SAAO;IACP,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,aAAa;IACjB;;AAEJ","sourcesContent":[".sidebarOption {\n    display: flex;\n    align-items: center;\n    height: 40px;\n    padding: 0 10px;\n    border-top-right-radius: 20px;\n    border-bottom-right-radius: 20px;\n    cursor: pointer;\n    color: #818181;\n}\n\n\n.sidebarOption:hover,\n.sidebarOption--active {\n    background-color: rgba(9, 110, 51, 0.2);\n}\n\n.sidebarOption:hover>p,\n.sidebarOption:hover>h3,\n.sidebarOption--active>p,\n.sidebarOption--active>h3 {\n    color: rgb(9, 110, 51);\n    /* color:#c04b37; */\n    font-weight: 800 !important;\n}\n\n.sidebarOption>h3 {\n    flex: 1;\n    margin-left: 10px;\n    font-size: 14px;\n    font-weight: 400;\n}\n\n.sidebarOption>p {\n    display: none;\n    font-weight: 300;\n}\n\n.sidebarOption--active>p {\n    display: inline !important;\n}\n\n.sidebarOption:hover>p {\n    display: inline;\n}\n\n.sidebarOption>.MuiSvgIcon-root {\n    padding: 5px;\n}\n\n@media only screen and (max-width: 500px) {\n    .sidebarOption>.MuiSvgIcon-root {\n        display: none;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
