import React from 'react'
import { Oval ,ThreeDots} from 'react-loader-spinner'

export default function ButtonLoader() {
    return (
        <Oval
            height={20}
            width={20}
            color="white"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#4fa94d"
            strokeWidth={6}
            strokeWidthSecondary={6}

        />
        // <ThreeDots 
        //     height="80" 
        //     width="80" 
        //     radius="9"
        //     color="#4fa94d" 
        //     ariaLabel="three-dots-loading"
        //     wrapperStyle={{}}
        //     secondaryColor="#4fa94d"
        //     wrapperClassName=""
        //     visible={true}
        // />
    )

}
