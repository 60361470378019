// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-up-container {
    min-height: 100vh;
}

.form-container {
    border-radius: 8px;
    border: 1px solid #dadce0;
    width: 400px;
    max-width: 100vw;
}

.step {
    /* transition: all 0.2s; */
}

.form-body label {
    font-weight: 500;
}

.form-body input,
.form-body select {
    border-radius: 4px;
    color: #202124;
    font-size: 16px;
    margin: 2px;
    padding: 8px 10px;
    border: 1px solid lightgray;
    transition: all 0.2s;
}

.form-body input:focus {
    border: 1px solid #096e33;
    outline: 1px solid #096e33;
}

.form-footer button {
    background: #096e33;
    border-radius: 16px;
    padding: 6px 20px;
    outline: none;
    border: none;
    color: white;
}

.form-footer button:hover {
    background: rgba(9, 110, 51, 0.95);
}

.form-footer button:disabled {
    opacity: 0.7;
}

.field-error {
    font-size: 12px;
    font-weight: 500;
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/Signup/Signup.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;IAGI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,WAAW;IACX,iBAAiB;IACjB,2BAA2B;IAC3B,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;IACzB,0BAA0B;AAC9B;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,UAAU;AACd","sourcesContent":[".sign-up-container {\n    min-height: 100vh;\n}\n\n.form-container {\n    border-radius: 8px;\n    border: 1px solid #dadce0;\n    width: 400px;\n    max-width: 100vw;\n}\n\n.step {\n    /* transition: all 0.2s; */\n}\n\n.form-body label {\n    font-weight: 500;\n}\n\n.form-body input,\n.form-body select {\n    -webkit-border-radius: 4px;\n    border-radius: 4px;\n    color: #202124;\n    font-size: 16px;\n    margin: 2px;\n    padding: 8px 10px;\n    border: 1px solid lightgray;\n    transition: all 0.2s;\n}\n\n.form-body input:focus {\n    border: 1px solid #096e33;\n    outline: 1px solid #096e33;\n}\n\n.form-footer button {\n    background: #096e33;\n    border-radius: 16px;\n    padding: 6px 20px;\n    outline: none;\n    border: none;\n    color: white;\n}\n\n.form-footer button:hover {\n    background: rgba(9, 110, 51, 0.95);\n}\n\n.form-footer button:disabled {\n    opacity: 0.7;\n}\n\n.field-error {\n    font-size: 12px;\n    font-weight: 500;\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
