import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./Index.css";
import axios from "axios";
import { UseAuthContextHook } from "../../context/AuthContext";
import { API_URLS } from "../../Utils/API_URLS";
import ButtonLoader from "../../Utils/ButtonLoader";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

export default function MailReceiver() {
  const { isLoggedIn } = UseAuthContextHook();

  const [sentMailList, setSentMailList] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (isLoggedIn || !!localStorage.getItem("jwt")) {
      // getMails();
      const intervalId = setInterval(getMails, 3000);

      return () => {
        clearInterval(intervalId); // Clear the interval when the component unmounts
      };
    }
  }, [isLoggedIn]);

  const [read,setRead] = useState([])

  const getMails = async () => {
    // setLoader(true);
    try {
      let { data } = await axios.get(API_URLS.receiveMail, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      });

      setLoader(false);
      console.log(data, "aa");
      const r=data[0]?.isRead
      setRead(r)

      setSentMailList(data || []);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  console.log(read)

  const customStyles = {
    rows: {
      style: {
        minHeight: "46px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        // textAlign: "center",
        color: "#000000",
        fontFamily: "'Inter',sans-serif",
      },
    },
    cells: {
      style: {
        width: "100px",
        paddingLeft: "8px",
        paddingRight: "8px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "19px",
        textAlign: "center",
        color: "#000000",
        fontFamily: "'Inter',sans-serif",
      },
    },
  };
  const nav = useNavigate();


  //show am pm
  function formatDateWithTime(dateString) {
    const date = new Date(dateString);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString(undefined, options);
  }


  const getEmailAddress = (fullNameWithEmail) => {
    const regex = /<|>/;

    const isNamePresent = regex.test(fullNameWithEmail);
    if (isNamePresent) {
      const emailStartIndex = fullNameWithEmail.indexOf("<") + 1;
      const emailEndIndex = fullNameWithEmail.indexOf(">");
      const email = fullNameWithEmail.substring(emailStartIndex, emailEndIndex);
      return email;
    } else {
      return fullNameWithEmail;
    }
  };

  const columns = [
    // {
    //   name: "Index",
    //   selector: (row, index) => index + 1,
    // },
    {
      name: "From",
      selector: (row) => (
        <div
          onClick={() => {
            nav(`/mail`, { state: { DATA: row } });
          }}
          style={{ cursor: "pointer" }}
        >
          {getEmailAddress(row?.from)}
        </div>
      ),
    conditionalCellStyles: [
      {
        when: (row) => !row.isRead,
        style: {
          backgroundColor: '#cee2d6', // Change the background color to black when isRead is false
        },
      },
    ],
      sortable: true,
    },
    {
      name: "To",
      selector: (row) => (
        <div
          onClick={() => {
            nav(`/mail`, { state: { DATA: row } });
          }}
          style={{ cursor: "pointer" }}
        >
          {row?.to}
        </div>
      ),
      conditionalCellStyles: [
        {
          when: (row) => !row.isRead,
          style: {
            backgroundColor: '#cee2d6', // Change the background color to black when isRead is false
          },
        },
      ],
    },
    {
      name: "Time",
      selector: (row) => (
        <div
          onClick={() => {
            nav(`/mail`, { state: { DATA: row } });
          }}
          style={{ cursor: "pointer" }}
        >
          {formatDateWithTime(row?.createdAt)}
        </div>
      ),
      conditionalCellStyles: [
        {
          when: (row) => !row.isRead,
          style: {
            backgroundColor: '#cee2d6', // Change the background color to black when isRead is false
          },
        },
       
      ],
    },
    // {
    //   // name: "Content",
    //   selector: (row) => row?.content,
    // },
    // {
    //   name: "Size (MB)",
    //   selector: (row) => (
    //     <div
    //     onClick={() => {
    //       nav(`/mail`, { state: { DATA: row } });
    //     }}
    //     style={{ cursor: "pointer" }}
    //   >
    //     {bytesToMB(row?.objectSize)}
    //   </div>
    // )
      
    // },
  ];

  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);

  useEffect(() => {
    if (search) {
      let filterddata = sentMailList?.filter((mapping) => {
        return (
          String(mapping?.from)
            .toLowerCase()
            .includes(String(search).toLowerCase()) ||
          String(mapping?.to)
            .toLowerCase()
            .includes(String(search).toLowerCase()) ||
          String(mapping?.content)
            .toLowerCase()
            .includes(String(search).toLowerCase())
        );
      });
      setFilteredData(filterddata);
    } else {
      setFilteredData(sentMailList);
    }
  }, [search, sentMailList]);

  return (
    <>
      <div className="dashboard ">
        {loader ? (
          <div className="d-flex justify-content-center">
            <ButtonLoader />
          </div>
        ) : (
          <>
            <div>
              <DataTable
                columns={columns}
                data={filterData}
                progressPending={loader}
                customStyles={customStyles}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[100, 50, 20, 10]}
                pagination
                paginationComponentOptions={{
                  rowsPerPageText: "Records per page:",
                  rangeSeparatorText: "out of",
                }}
                highlightOnHover
                subHeader
                subHeaderComponent={
                  <input
                    type="text"
                    placeholder="Search here"
                    className=" form-control w-25"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                }
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
