import { Formik } from 'formik';
import React from 'react'
import * as Yup from 'yup';
import Logo from '../../assets/LOGO.png'
import { UseAuthContextHook } from '../../context/AuthContext';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom/dist';
import ButtonLoader from '../../Utils/ButtonLoader';

import './Index.css'

export default function Login() {

    const { handleLogin } = UseAuthContextHook();
    const [showPassword, setShowPassword] = useState(false);
    const [loader, setLoader] = useState(false);

    const navigate = useNavigate();

    return (
        <div className='sign-up-container d-flex justify-content-center align-items-center'>
            <div className='form-container py-5 px-4 d-flex'>
                <Formik
                    initialValues={{ username: '', password: '' }}
                    validationSchema={Yup.object({
                        username: Yup.string().required('First Name is Required'),
                        password: Yup.string().required("Password is Required")
                    })}
                    onSubmit={(values) => {
                        // console.log(values);
                        setLoader(true)

                        handleLogin(values).then((res) => {
                          
                         
                            navigate('/dashboard')
                            setLoader(false)
                            toast.success('Logged in successfully')
                        }).catch((error) => {
                            // console.log(error);
                            toast.error(error?.response?.data?.message ? error?.response?.data?.message : "Some Error Occured")
                            setLoader(false)
                        })
                    }}
                >
                    {
                        formik => (
                            <form onSubmit={formik.handleSubmit}
                                className='d-flex flex-column gap-3 step w-100'>
                                <div className='form-header d-flex flex-column align-items-center justify-content-center gap-3'>
                                    <img className='m-auto' width='150px' src={Logo} alt="Logo" />
                                    <h5>Login to your Udenz account</h5>
                                </div>

                                <div className='form-body d-flex flex-column gap-3'>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor="username">Username</label>
                                        <input
                                            name='username'
                                            type="text"
                                            placeholder='Enter Your Udenz Mail'
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps('username')}
                                        />
                                        {formik.touched.username && formik.errors.username ? (
                                            <div className='field-error'>{formik.errors.username}</div>
                                        ) : null}
                                    </div>

                                    <div className='d-flex flex-column'>
                                        <label htmlFor="password">Password</label>
                                        <div className='d-flex'>
                                            <input
                                                className='flex-grow-1'
                                                name='password'
                                                type={showPassword ? "text" : "password"}
                                                placeholder='Enter Your password'
                                                onChange={formik.handleChange}
                                                {...formik.getFieldProps('password')}
                                            />
                                            <div className='d-flex align-items-center'>
                                                {
                                                    showPassword ?
                                                        <FaRegEye onClick={() => setShowPassword(false)} role='button' className='position-absolute' style={{ marginLeft: "-30px" }} /> :
                                                        <FaRegEyeSlash onClick={() => setShowPassword(true)} role='button' className='position-absolute' style={{ marginLeft: "-30px" }} />
                                                }
                                            </div>
                                        </div>
                                        {formik.touched.password && formik.errors.password ? (
                                            <div className='field-error'>{formik.errors.password}</div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className='form-footer mt-3'>
                                    <div className='d-flex justify-content-center'>
                                        <button disabled={loader} type='submit'>
                                            {
                                                loader ? <ButtonLoader /> : 'Login'
                                            }
                                        </button>
                                    </div>
                                </div>

                                <div className='m-auto mt-3 no-account-div'>
                                 <Link to='/forgetPassword'>Forgot Password?</Link>
                                </div>
                            </form>
                        )
                    }
                </Formik>

            </div>
        </div>
    )
}
