// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.app-body {
    display: flex;
    flex-grow: 1;
    /* height: 100vh; */
}

.progress .active {
    animation: progress-bar-stripes 1s linear infinite;
}
*{
    margin: 0px;
}
hr{
    margin:5px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IAGI,kDAAkD;AACtD;AACA;IACI,WAAW;AACf;AACA;IACI,UAAU;AACd","sourcesContent":[".app {\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n}\n\n.app-body {\n    display: flex;\n    flex-grow: 1;\n    /* height: 100vh; */\n}\n\n.progress .active {\n    -webkit-animation: progress-bar-stripes 1s linear infinite;\n    -o-animation: progress-bar-stripes 1s linear infinite;\n    animation: progress-bar-stripes 1s linear infinite;\n}\n*{\n    margin: 0px;\n}\nhr{\n    margin:5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
