import React from "react";
import "./SidebarOption.css";

function SidebarOption({ Icon, title, number, selected }) {
  return (
    <div className={`sidebarOption mt-3 ${selected && "sidebarOption--active  mt-3"}`}>
      <Icon />
      <h3 className="mb-0">{title}</h3>
      <p className="mb-0">{number}</p>
    </div>
  );
}

export default SidebarOption;
