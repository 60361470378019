// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a#ro {
    text-decoration: none;
    color: black;
    overflow: hidden;
    /* font-size: 14px; */
}`, "",{"version":3,"sources":["webpack://./src/components/SentMailList/Index.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,YAAY;IACZ,gBAAgB;IAChB,qBAAqB;AACzB","sourcesContent":["a#ro {\n    text-decoration: none;\n    color: black;\n    overflow: hidden;\n    /* font-size: 14px; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
