import React, { useEffect, useState } from "react";
import "./EmailList.css";
import { UseAuthContextHook } from "../../context/AuthContext";
import axios from "axios";
import { API_URLS } from "../../Utils/API_URLS";
import ButtonLoader from "../../Utils/ButtonLoader";
import DataTable from "react-data-table-component";
import SearchIcon from "@material-ui/icons/Search";





function DashboardPage({ emails }) {
  const { isLoggedIn } = UseAuthContextHook();
  const [mailsList, setMailsList] = useState([]);
  const [loader, setLoader] = useState(true);

  const getMails = async () => {
    // setLoader(true);
    try {
      const { data } = await axios.get(API_URLS.user, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "application/json",
        },
      });

      setLoader(false);
      // console.log(data, "aa");
      setMailsList(data || []);
    } catch (error) {
      setLoader(false);
      // console.log(error);
    }
  };



  const customStyles = {
    rows: {
      style: {
        minHeight: "46px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "18px",
        lineHeight: "22px",
        // textAlign: "center",
        color: "#000000",
        fontFamily: "'Inter',sans-serif",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "19px",
        textAlign: "center",
        color: "#000000",
        fontFamily: "'Inter',sans-serif",
      },
    },
  };

  useEffect(() => {
    if (isLoggedIn || !!localStorage.getItem("jwt")) {
      const intervalId = setInterval(getMails, 3000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isLoggedIn]);

  function bytesToMB(bytes) {
    return parseFloat(bytes) / 1000000 ; 
  }


  const columns = [

    {
      name: "Name",
      selector: (row) => row?.firstName,
      sortable: true,
      
    },
    {
      name: "Last Name",
      selector: (row) => row?.lastName,
    },
    {
      name: "Registered Date",
      selector: (row) => row?.createdAt.slice(0, 10),
    },
    {
      name: "Given Space (MB)",
      selector: (row) => bytesToMB(row?.MAX_STORAGE_CAPACITY) + " MB",
    },
    {
      name: "Available Space (MB)",

      selector: (row) => {
        const givenSpaceMB = bytesToMB(row?.MAX_STORAGE_CAPACITY);
        const availableSpaceMB = givenSpaceMB - bytesToMB(row?.spaceAvailable);
        const substring = parseFloat(availableSpaceMB.toString().substring(0, 6))
        if(availableSpaceMB>0)
        {
          return `${substring} MB`;
        }else{
          return `1 MB`;
        }
       
      }
    },
  ];

  const [search, setSearch] = useState("");
  const [filterData, setFilteredData] = useState([]);

  useEffect(() => {
    if (search) {
      let filterddata = mailsList?.filter((mapping) => {
        return (
          String(mapping?.firstName)
            .toLowerCase()
            .includes(String(search).toLowerCase()) ||
          String(mapping?.lastName)
            .toLowerCase()
            .includes(String(search).toLowerCase())
        );
      });
      setFilteredData(filterddata);
    } else {
      setFilteredData(mailsList);
    }
  }, [search, mailsList]);

  return (
    <React.Fragment>
      <div className="dashboard ">
        {loader ? (
          <div className="d-flex justify-content-center">
            <ButtonLoader />
          </div>
        ) : (
          <div className="box">
            <DataTable
              columns={columns}
              data={filterData}
              progressPending={loader}
              customStyles={customStyles}
              paginationPerPage={10}
                paginationRowsPerPageOptions={[100, 50, 20, 10]}
                pagination
                paginationComponentOptions={{
                  rowsPerPageText: "Records per page:",
                  rangeSeparatorText: "out of",
                }}
              highlightOnHover
              subHeader
              subHeaderComponent={
                <>
                {/* <SearchIcon style={{position:"absolute",right:"10px"}}/> */}
                <input
                  type="text"
                  placeholder="Search here"
                  className=" form-control w-25"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                </>
              }
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default DashboardPage;
