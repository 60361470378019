// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    flex: 0.3 1;
    min-width: 20%;
    padding-right: 20px;
}

.sidebar-compose {
    margin-top: 15px !important;
    margin-left: 10px !important;
    margin-bottom: 15px !important;
    text-transform: capitalize !important;
    color: grey;
    padding: 15px 20px !important;
    border-radius: 30px !important;
    box-shadow: 0px 2px 5px -2px rgb(0, 0, 0, 0.75);
}

.sidebar-footer {
    display: flex;
    justify-content: center;
}

.sidebar-link {
    text-decoration: none;
}

.logout-text {
    text-transform: initial;
    font-weight: 500;
}

@media only screen and (max-width: 320px) {

    .sidebar{
        min-width: 20%;
    }    
    .sidebar-footerIcons>.MuiIconButton-root {
        padding: 1px !important;
    }

    .sidebar-compose {
        margin-left: 3px !important;
        padding: 10px 8px !important;
    }
}

.MuiButton-label {
    justify-content:start !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,WAAS;IACT,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,4BAA4B;IAC5B,8BAA8B;IAC9B,qCAAqC;IACrC,WAAW;IACX,6BAA6B;IAC7B,8BAA8B;IAC9B,+CAA+C;AACnD;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;;IAEI;QACI,cAAc;IAClB;IACA;QACI,uBAAuB;IAC3B;;IAEA;QACI,2BAA2B;QAC3B,4BAA4B;IAChC;AACJ;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".sidebar {\n    flex: 0.3;\n    min-width: 20%;\n    padding-right: 20px;\n}\n\n.sidebar-compose {\n    margin-top: 15px !important;\n    margin-left: 10px !important;\n    margin-bottom: 15px !important;\n    text-transform: capitalize !important;\n    color: grey;\n    padding: 15px 20px !important;\n    border-radius: 30px !important;\n    box-shadow: 0px 2px 5px -2px rgb(0, 0, 0, 0.75);\n}\n\n.sidebar-footer {\n    display: flex;\n    justify-content: center;\n}\n\n.sidebar-link {\n    text-decoration: none;\n}\n\n.logout-text {\n    text-transform: initial;\n    font-weight: 500;\n}\n\n@media only screen and (max-width: 320px) {\n\n    .sidebar{\n        min-width: 20%;\n    }    \n    .sidebar-footerIcons>.MuiIconButton-root {\n        padding: 1px !important;\n    }\n\n    .sidebar-compose {\n        margin-left: 3px !important;\n        padding: 10px 8px !important;\n    }\n}\n\n.MuiButton-label {\n    justify-content:start !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
