import { Button, IconButton } from "@material-ui/core";
import React from "react";
import "./Sidebar.css";
import InboxIcon from "@material-ui/icons/Inbox";
import NearMeIcon from "@material-ui/icons/NearMe";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SidebarOption from "./SidebarOption";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URLS } from "../../Utils/API_URLS";

function Sidebar({ emails }) {
  const navigate = useNavigate();

  const location = useLocation();

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        API_URLS.logout,
        {
          refreshToken: localStorage.getItem("refresh-token"),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      // console.log(data);
      localStorage.clear();
      navigate("/");
    } catch (error) {
      // console.log(error);
    }
  };

const redirect=()=>{
  navigate("/changePassword")
}

  return (
    <div className="sidebar d-flex flex-column justify-content-between">
      <div>
        <div>
          <Link to="/dashboard" className="sidebar-link">
            <SidebarOption
              Icon={InboxIcon}
              title="User"
              selected={location.pathname === "/dashboard"}
            />
          </Link>
          <Link to="/mailReceiver" className="sidebar-link">
            <SidebarOption
              Icon={NearMeIcon}
              title="All Mail List"
              selected={location.pathname === "/mailReceiver"}
            />
          </Link>
        </div>
      </div>

      <div className="pb-2">
        <div className="">
          <Button onClick={redirect} role="button" className="w-100">
            <ExitToAppIcon fontSize="small" className="" />
            <p className="mb-0 ps-2 logout-text">Change Password</p>
          </Button>
        </div>
        <div className="">
          <Button onClick={handleLogout} role="button" className="w-100">
            <ExitToAppIcon fontSize="small" className="" />
            <p className="mb-0 ps-2 logout-text">Logout</p>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
