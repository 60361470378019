import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Mail from "./components/Mail/Mail";
import { db } from "./firebase";
import MainLayout from "./layout/MainLayout";
import AuthContext from "./context/AuthContext";
import Signup from "./components/Signup/Index";
import Login from "./components/LoginNew/Index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardPage from "./components/EmailList/Dashboard";
import MailReceiver from "./components/SentMailList/Index";
//
import Reset from "./ResetPassword/Reset";
import ForgotPage from "./components/Forget/ForgotPage";
import ConfirmPage from "./components/Confirm/ConfirmPage";

function App() {
  const [emails, setEmails] = useState([]);
  useEffect(() => {
    db.collection("emails")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) =>
        setEmails(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
  }, []);

  return (
    <Router>
      <AuthContext>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgetPassword" element={<ForgotPage />} />
          <Route path="/reset-password" element={<ConfirmPage />} />
          <Route path="/" element={<MainLayout />}>
            <Route path="mail" element={<Mail />} />
            <Route
              path="dashboard"
              element={<DashboardPage emails={emails} />}
            />
            <Route path="mailReceiver" element={<MailReceiver />} />
            <Route path="changePassword" element={<Reset />} />
          </Route>
        </Routes>
        <ToastContainer theme="dark" />
      </AuthContext>
    </Router>
  );

}

export default App;
