import React, { useEffect, useState } from "react";
import "./Mail.css";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import pdfIcon from "../../assets/pdf icon (1).png";
import csvIcon from "../../assets/csvIcon.png";
import docxIcon from "../../assets/docxIcon.png";
import { API_URLS } from "../../Utils/API_URLS";
import axios from "axios";

function Mail() {
  const navigate = useNavigate();
  const location = useLocation("");

  let get = location?.state?.DATA;


  console.log(get,"get")

 

  const handleRead = async () => {
    try {
      let { data } = await axios.post(
        API_URLS.isRead,
        { emailId:[`${get?._id}`] }, // Access the id property of the get object
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      // navigate("/", { state: { data: data.data } });
      // console.log(data?.data,"data**********")
      // Handle the response data if needed
    } catch (error) {
      // console.error("Error marking as read:", error);
    }
  };

  useEffect(() => {
    handleRead(); // Call the handleRead function when the component mounts
  }, []);


  //download Images
  function handleDownloadImage(attachmentURL, filename) {
    const link = document.createElement("a");
    link.href = attachmentURL;
    link.target = "_blank";
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  //download CSV files
  const handleDownloadCSV = (csvUrl) => {
    const a = document.createElement("a");
    a.href = csvUrl;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  //open pdf file
  const openPdfInNewTab = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  //open docx
  const handleDownloadDocx = (docxUrl, filename) => {
    const link = document.createElement("a");
    link.href = docxUrl;
    link.download = filename || "document.docx"; // Set the default filename if not provided
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //get Emails
  const getEmailAddress = (fullNameWithEmail) => {
    const regex = /<|>/;
    const isNamePresent = regex.test(fullNameWithEmail);
    if (isNamePresent) {
      const emailStartIndex = fullNameWithEmail.indexOf("<") + 1;
      const emailEndIndex = fullNameWithEmail.indexOf(">");
      const email = fullNameWithEmail.substring(emailStartIndex, emailEndIndex);
      return email;
    } else {
      return fullNameWithEmail;
    }
  };

  return (
    <div className="mail">
      <div className="mail-tools">
        <div className="mail-toolsLeft">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>

          {/* <IconButton>
            <MoveToInboxIcon />
          </IconButton>

          <IconButton>
            <ErrorIcon />
          </IconButton>

          <IconButton>
            <DeleteIcon />
          </IconButton>

          <IconButton>
            <EmailIcon />
          </IconButton>

          <IconButton>
            <WatchLaterIcon />
          </IconButton>

          <IconButton>
            <CheckCircleIcon />
          </IconButton>

          <IconButton>
            <LabelImportantIcon />
          </IconButton>

          <IconButton>
            <MoreVertIcon />
          </IconButton> */}
        </div>
        {/* <div className="mail-toolsRight">
          <IconButton>
            <UnfoldMoreIcon />
          </IconButton>

          <IconButton>
            <PrintIcon />
          </IconButton>

          <IconButton>
            <ExitToAppIcon />
          </IconButton>
        </div> */}
      </div>
      <div className="mail-body">
        <div className="mail-bodyHeader">
          <div className="mail-subject">
            <h2>{getEmailAddress(get?.from)}</h2>
          </div>
          <div id="id">
            <p className="">{get?.createdAt.slice(0, 10)}</p>
          </div>
        </div>

        <div className="mail-message">
          <h5 className="contents">{get?.content}</h5>
        </div>
        <div className="" >
          <div className="fw-bold mb-3">
            {get?.attachments?.length > 0 ? (
              <>
              <hr style={{width:"100%",margin:"0px",marginBottom:"10px"}}/>
                <span style={{marginLeft:"20px"}}>
                  {get?.attachments?.length}&nbsp;&nbsp;attachment &nbsp;•&nbsp;
                  <span style={{ fontWeight: "400" }}>Scanned by udenz</span>
                </span>
              </>
            ) : (
              ""
            )}
          </div>
          {get?.attachments.map((attachment, index) => (
            <div key={index} className=" ms-4">
              {attachment?.ContentType === "image" ||
              attachment?.ContentType === "image/png" ||
              attachment?.ContentType === "image/jpeg" ||
              attachment?.ContentType === "image/jpg" ? (
                <img
                  key={index}
                  src={attachment.attatchmentURL}
                  alt=""
                  height="80px"
                  className="rounded me-5"
                  onClick={() =>
                    handleDownloadImage(attachment?.attatchmentURL)
                  }
                />
              ) : (
                <>
                  {attachment?.ContentType === "application/pdf" ||
                  attachment?.ContentType === "application/octet-stream" ? (
                    <img
                      key={index}
                      src={pdfIcon}
                      alt=""
                      height="100px"
                      className="rounded me-5"
                      onClick={() =>
                        openPdfInNewTab(attachment?.attatchmentURL)
                      }
                    />
                  ) : attachment?.ContentType ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                    <img
                      key={index}
                      src={docxIcon} // Assuming you have the Word icon image source imported
                      alt=""
                      height="100px"
                      className="rounded me-5"
                      onClick={() =>
                        handleDownloadDocx(attachment?.attatchmentURL)
                      }
                    />
                  ) : attachment?.ContentType === "text/csv" ? (
                    <img
                      key={index}
                      src={csvIcon}
                      alt=""
                      height="100px"
                      className="rounded me-5"
                      onClick={() =>
                        handleDownloadCSV(attachment?.attatchmentURL)
                      }
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Mail;
