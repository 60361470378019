// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard{
    width:80%;
}

@media only screen and (max-width: 1024px) {

    /* .emailList-settingsLeft>.MuiIconButton-root {
        padding-left: 3px;
        padding-right: 3px;
    }

    .emailList-settingsRight>.MuiIconButton-root {
        padding-left: 3px;
        padding-right: 3px;
    }

    .emailList-settingsLeft {
        margin-left: 10px;
    }

    .emailList-settingsRight {
        margin-right: 10px;
    } */
    .dashboard{
        width: 80%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/EmailList/EmailList.css"],"names":[],"mappings":"AAAA;IACI,SAAS;AACb;;AAEA;;IAEI;;;;;;;;;;;;;;;;OAgBG;IACH;QACI,UAAU;IACd;AACJ","sourcesContent":[".dashboard{\n    width:80%;\n}\n\n@media only screen and (max-width: 1024px) {\n\n    /* .emailList-settingsLeft>.MuiIconButton-root {\n        padding-left: 3px;\n        padding-right: 3px;\n    }\n\n    .emailList-settingsRight>.MuiIconButton-root {\n        padding-left: 3px;\n        padding-right: 3px;\n    }\n\n    .emailList-settingsLeft {\n        margin-left: 10px;\n    }\n\n    .emailList-settingsRight {\n        margin-right: 10px;\n    } */\n    .dashboard{\n        width: 80%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
