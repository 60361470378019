// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-account-div {
    font-size: 14px;
    font-weight: 500;
}

.no-account-div a {
    color: rgb(9, 110, 51);
}`, "",{"version":3,"sources":["webpack://./src/components/LoginNew/Index.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".no-account-div {\n    font-size: 14px;\n    font-weight: 500;\n}\n\n.no-account-div a {\n    color: rgb(9, 110, 51);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
