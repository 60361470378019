const BASE_URL = "https://backend.udenz.org"

export const API_URLS = {
    // AUTH API's
    signUp: `${BASE_URL}/v1/auth/singUp`,
    login: `${BASE_URL}/v1/auth/login`,
    logout: `${BASE_URL}/v1/auth/logout`,
    forgotPassword: `${BASE_URL}/v1/auth/forgot-password`,
    resetPassword: `${BASE_URL}/v1/auth/reset-password`,

    // Mail API's
    user:`${BASE_URL}/v1/users`,
    receiveMail: `${BASE_URL}/v1/users/allApplicationEmails `,
    changePassword: `${BASE_URL}/v1/auth/change-password `,
    isRead: `${BASE_URL}/v1/mail/IsRead`,  
}