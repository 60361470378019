// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid whitesmoke;
}

.header-left {
    display: flex;
    align-items: center;
}

.header-left > img {
    object-fit: contain;
    height: 35px;
    margin-left: 5px;
}

.header-middle {
    display: flex;
    align-items: center;
    flex: 0.7 1;
    background-color: whitesmoke;
    padding: 8px 15px;
    margin: 10px;
    border-radius: 10px;
}

.header-middle > .MuiSvgIcon-root {
    color: gray;
}

.header-middle > input {
    border: none;
    width: 100%;
    padding: 10px;
    outline: none;
    font-size: medium;
    background-color: transparent;
}

.header-right{
    display: flex;
    padding-right: 20px;
    align-items: center;
}


@media only screen and (max-width: 500px) {
    .header-left > .MuiIconButton-root{
        display: none !important;
    }
  
    .header-middle {
        margin: 10px 0px;
    }  
    .header-right > .MuiIconButton-root{
        padding: 5px !important;
        display: none !important;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mCAAmC;AACvC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAS;IACT,4BAA4B;IAC5B,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,aAAa;IACb,iBAAiB;IACjB,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;;AAGA;IACI;QACI,wBAAwB;IAC5B;;IAEA;QACI,gBAAgB;IACpB;IACA;QACI,uBAAuB;QACvB,wBAAwB;IAC5B;EACF","sourcesContent":[".header{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    border-bottom: 1px solid whitesmoke;\n}\n\n.header-left {\n    display: flex;\n    align-items: center;\n}\n\n.header-left > img {\n    object-fit: contain;\n    height: 35px;\n    margin-left: 5px;\n}\n\n.header-middle {\n    display: flex;\n    align-items: center;\n    flex: 0.7;\n    background-color: whitesmoke;\n    padding: 8px 15px;\n    margin: 10px;\n    border-radius: 10px;\n}\n\n.header-middle > .MuiSvgIcon-root {\n    color: gray;\n}\n\n.header-middle > input {\n    border: none;\n    width: 100%;\n    padding: 10px;\n    outline: none;\n    font-size: medium;\n    background-color: transparent;\n}\n\n.header-right{\n    display: flex;\n    padding-right: 20px;\n    align-items: center;\n}\n\n\n@media only screen and (max-width: 500px) {\n    .header-left > .MuiIconButton-root{\n        display: none !important;\n    }\n  \n    .header-middle {\n        margin: 10px 0px;\n    }  \n    .header-right > .MuiIconButton-root{\n        padding: 5px !important;\n        display: none !important;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
