import React from 'react'
import './Signup.css'
import Logo from '../../assets/LOGO.png'
import { useState } from 'react'

import { Formik } from 'formik';
import * as Yup from 'yup';

import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import { UseAuthContextHook } from '../../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import ButtonLoader from '../../Utils/ButtonLoader';

export default function Signup() {

    const { handleSignUp } = UseAuthContextHook()

    const [stepNumber, setStepNumber] = useState(1);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [loader, setLoader] = useState(false);

    const [signUpData, setSignUpData] = useState({
        firstName: "",
        lastname: "",
        dob: "",
        gender: "",
        email: "",
        password: "",
        confirmPassword: ""
    });

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        // console.log(location.hash);
        if (location.hash === "#step-1") setStepNumber(1)
        else if (location.hash === "#step-2") setStepNumber(2)
        else if (location.hash === "#step-3") setStepNumber(3)
        else if (location.hash === "#step-4") setStepNumber(4)
        else setStepNumber(1)
    }, [location])

    return (
        <div className='sign-up-container d-flex justify-content-center align-items-center'>
            <div className='form-container py-5 px-4 d-flex'>

                {/* Step 1 */}
                <Formik
                    initialValues={{ firstName: '', surname: '' }}
                    validationSchema={Yup.object({
                        firstName: Yup.string()
                            .max(15, 'Must be 15 characters or less')
                            .required('First Name is Required')
                    })}
                    onSubmit={(values) => {
                        // console.log(values);
                        setSignUpData((preValue) => ({
                            ...preValue,
                            firstName: values.firstName,
                            lastname: values.surname
                        }))
                        navigate('#step-2')
                        setStepNumber(2)
                    }}
                >
                    {
                        formik => (
                            <form onSubmit={formik.handleSubmit}
                                style={stepNumber !== 1 ? { width: '0px', height: '0px', overflow: 'hidden' } : { width: '100%', height: '100%' }} className='d-flex flex-column gap-3 step'>
                                <div className='form-header d-flex flex-column align-items-center justify-content-center gap-3'>
                                    <img className='m-auto' width='150px' src={Logo} alt="Logo" />
                                    <h5>Create a Udenz account</h5>
                                </div>

                                <div className='form-body d-flex flex-column gap-3'>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor="firstName">First Name</label>
                                        <input
                                            name='firstName'
                                            type="text"
                                            placeholder='Enter Your First Name'
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps('firstName')}
                                        />
                                        {formik.touched.firstName && formik.errors.firstName ? (
                                            <div className='field-error'>{formik.errors.firstName}</div>
                                        ) : null}
                                    </div>

                                    <div className='d-flex flex-column'>
                                        <label htmlFor="surname">Surname (optional)</label>
                                        <input
                                            name='surname'
                                            type="text"
                                            placeholder='Enter Your Surname'
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps('surname')}
                                        />
                                        {formik.touched.surname && formik.errors.surname ? (
                                            <div className='field-error'>{formik.errors.surname}</div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className='form-footer mt-3'>
                                    <div className='d-flex justify-content-end'>
                                        <button type='submit'>Next</button>
                                    </div>
                                </div>
                            </form>
                        )
                    }
                </Formik>


                {/* Step 2 */}
                <Formik
                    initialValues={{ dob: '', gender: '' }}
                    validationSchema={Yup.object({
                        dob: Yup.string().required('DOB is Required'),
                        gender: Yup.string().required("Gender is Required")
                    })}
                    onSubmit={(values) => {
                        // console.log(values);
                        setSignUpData((preValue) => ({
                            ...preValue,
                            dob: values.dob,
                            gender: values.gender
                        }))
                        navigate('#step-3')
                        setStepNumber(3)
                    }}
                >
                    {
                        formik => (
                            <form onSubmit={formik.handleSubmit} style={stepNumber !== 2 ? { width: '0px', height: '0px', overflow: 'hidden' } : { width: '100%', height: '100%' }} className='d-flex flex-column gap-3 step'>
                                <div className='form-header d-flex flex-column align-items-center justify-content-center gap-3'>
                                    <img className='m-auto' width='150px' src={Logo} alt="Logo" />
                                    <h5>Basic Information</h5>
                                </div>

                                <div className='form-body d-flex flex-column gap-3'>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor="dob">DOB</label>
                                        <input
                                            name='dob'
                                            type="date"
                                            placeholder='Enter Your First Name'
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps('dob')}
                                        />
                                        {formik.touched.dob && formik.errors.dob ? (
                                            <div className='field-error'>{formik.errors.dob}</div>
                                        ) : null}
                                    </div>

                                    <div className='d-flex flex-column'>
                                        <label htmlFor="gender">Gender</label>
                                        <select name="gender" id="gender"
                                            onChange={formik.handleChange}
                                            {...formik.getFieldProps('gender')}
                                        >
                                            <option value="" disabled>Select Your Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select>
                                        {formik.touched.gender && formik.errors.gender ? (
                                            <div className='field-error'>{formik.errors.gender}</div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className='form-footer mt-3'>
                                    <div className='d-flex justify-content-end gap-3'>
                                        <button type='button' onClick={() => {
                                            setStepNumber(1);
                                            navigate('#step-1')
                                        }}>Previous</button>
                                        <button type='submit' >Next</button>
                                    </div>
                                </div>
                            </form>
                        )
                    }
                </Formik>

                {/* Step 3 */}
                <Formik
                    initialValues={{ username: '' }}
                    validationSchema={Yup.object({
                        username: Yup.string()
                            .max(100, 'Must be 100 characters or less')
                            .required('Username is Required')
                    })}
                    onSubmit={(values) => {
                        // console.log(values);
                        setSignUpData((preValue) => ({
                            ...preValue,
                            email: values.username,
                        }))
                        navigate('#step-4')
                        setStepNumber(4)
                    }}
                >
                    {
                        formik => (
                            <form
                                onSubmit={formik.handleSubmit}
                                style={stepNumber !== 3 ? { width: '0px', height: '0px', overflow: 'hidden' } : { width: '100%', height: '100%' }} className='d-flex flex-column gap-3 step'>
                                <div className='form-header d-flex flex-column align-items-center justify-content-center gap-3'>
                                    <img className='m-auto' width='150px' src={Logo} alt="Logo" />
                                    <h5 className='text-center'>Create a Udenz address for signing in to your account.</h5>
                                </div>

                                <div className='form-body d-flex flex-column gap-3'>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor="username">Username</label>
                                        <div className='d-flex'>
                                            <input
                                                style={{ paddingRight: "110px" }}
                                                className='flex-grow-1'
                                                name='username'
                                                type="text"
                                                placeholder='Enter Your username'
                                                {...formik.getFieldProps('username')}
                                                onChange={formik.handleChange}
                                            />
                                            <div className='d-flex align-items-center'>
                                                <p style={{ marginLeft: "-100px" }} className={stepNumber === 3 && 'position-absolute my-0'}>@udenz.org</p>
                                            </div>
                                        </div>
                                        {formik.touched.username && formik.errors.username ? (
                                            <div className='field-error'>{formik.errors.username}</div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className='form-footer mt-3'>
                                    <div className='d-flex justify-content-end gap-3'>
                                        <button type='button' onClick={() => {
                                            setStepNumber(2)
                                            navigate('#step-2')
                                        }}>Previous</button>
                                        <button type='submit'>Next</button>
                                    </div>
                                </div>
                            </form>
                        )
                    }
                </Formik>

                {/* Step 4 */}
                <Formik
                    initialValues={{ password: '', confirmPassword: '' }}
                    validationSchema={Yup.object({
                        password: Yup.string()
                            .min(8, 'Must be 8 characters or more')
                            .required('Password is Required'),
                        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], "Password don't match").required('Confirm Password is Required')
                    })}
                    onSubmit={(values) => {
                        // console.log(values);
                        setLoader(true)
                        let data = {
                            ...signUpData,
                            password: values.password,
                            confirmPassword: values.confirmPassword
                        }

                        // console.log(data, "finaldata")

                        setSignUpData((preValue) => ({
                            ...preValue,
                            password: values.password,
                            confirmPassword: values.confirmPassword
                        }))
                        // setStepNumber(2)

                        handleSignUp(data).then((res) => {
                            // console.log(res);
                            toast.success(res?.data?.message)
                            localStorage.setItem('jwt', res?.data?.tokens?.access?.token)
                            localStorage.setItem('refresh-token', res?.data?.tokens?.refresh?.token)
                            setLoader(false)
                            navigate('/inbox')
                        }).catch((error) => {
                            // console.log(error);
                            setLoader(false)
                            toast.error(error?.response?.data?.message ? error?.response?.data?.message : "Some Error Occured")
                        })
                    }}
                >
                    {
                        formik => (
                            <form
                                onSubmit={formik.handleSubmit}
                                style={stepNumber !== 4 ? { width: '0px', height: '0px', overflow: 'hidden' } : { width: '100%', height: '100%' }} className='d-flex flex-column gap-3 step'>
                                <div className='form-header d-flex flex-column align-items-center justify-content-center gap-3'>
                                    <img className='m-auto' width='150px' src={Logo} alt="Logo" />
                                    <h5 className='text-center'>Create a strong password with a mix of letters, numbers and symbols</h5>
                                </div>

                                <div className='form-body d-flex flex-column gap-3'>
                                    <div className='d-flex flex-column'>
                                        <label htmlFor="password">Password</label>
                                        <div className='d-flex'>
                                            <input
                                                name='password'
                                                className='flex-grow-1'
                                                type={showPassword ? "text" : "password"}
                                                placeholder='Enter Your Password'
                                                {...formik.getFieldProps('password')}
                                                onChange={formik.handleChange}
                                            />

                                            {
                                                stepNumber === 4 &&
                                                <div className='d-flex align-items-center'>
                                                    {
                                                        showPassword ?
                                                            <FaRegEye onClick={() => setShowPassword(false)} role='button' className='position-absolute' style={{ marginLeft: "-30px" }} /> :
                                                            <FaRegEyeSlash onClick={() => setShowPassword(true)} role='button' className='position-absolute' style={{ marginLeft: "-30px" }} />
                                                    }
                                                </div>
                                            }
                                        </div>
                                        {formik.touched.password && formik.errors.password ? (
                                            <div className='field-error'>{formik.errors.password}</div>
                                        ) : null}
                                    </div>

                                    <div className='d-flex flex-column'>
                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                        <div className='d-flex'>
                                            <input
                                                className='flex-grow-1'
                                                name='confirmPassword'
                                                type={showConfirmPassword ? "text" : "password"}
                                                placeholder='Confirm Your Password'
                                                {...formik.getFieldProps('confirmPassword')}
                                                onChange={formik.handleChange}
                                            />
                                            {
                                                stepNumber === 4 &&
                                                <div className='d-flex align-items-center'>
                                                    {
                                                        showConfirmPassword ?
                                                            <FaRegEye onClick={() => setShowConfirmPassword(false)} role='button' className='position-absolute' style={{ marginLeft: "-30px" }} /> :
                                                            <FaRegEyeSlash onClick={() => setShowConfirmPassword(true)} role='button' className='position-absolute' style={{ marginLeft: "-30px" }} />
                                                    }
                                                </div>
                                            }
                                        </div>
                                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                            <div className='field-error'>{formik.errors.confirmPassword}</div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className='form-footer mt-3'>
                                    <div className='d-flex justify-content-end gap-3'>
                                        <button type='button' onClick={() => {
                                            setStepNumber(3)
                                            navigate('#step-3')
                                        }}>Previous</button>
                                        <button disabled={loader} type='submit'>
                                            {
                                                loader ? <ButtonLoader /> :
                                                    'Create Account'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )
                    }
                </Formik>

            </div>

        </div>
    )
}
