import axios from 'axios';
import React, { createContext, useContext } from 'react'
import { useState } from 'react';
import { API_URLS } from '../Utils/API_URLS';
import { useNavigate } from 'react-router-dom';

const AuthContextHook = createContext();

export const UseAuthContextHook = () => {
    return useContext(AuthContextHook)
}

export default function AuthContext({ children }) {
    const nav=useNavigate()

    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const handleSignUp = async (data) => {
        return await axios.post(API_URLS.signUp, {
            firstName: data?.firstName,
            lastName: data?.lastname,
            email: `${data?.email}@udenz.org`,
            gender: data?.gender,
            dob: data?.dob,
            password: data?.password,
            confirmPassword: data?.confirmPassword
        })
    }

    


    const handleLogin = async (data) => {
        let res = await axios.post(API_URLS.login, {
            email: data?.username,
            password: data?.password
        }).then((res)=>{
            const admin=res?.data?.user?.role
            localStorage.setItem("role",admin)
            localStorage.setItem('jwt', res?.data?.tokens?.access?.token)
            localStorage.setItem('refresh-token', res?.data?.tokens?.refresh?.token)
        })
        setIsLoggedIn(true)
        // nav("/dashboard")
        return res
    }
    
   
      

    let exportableData = {
        handleSignUp,
        handleLogin,
        isLoggedIn
    }

    return (
        <AuthContextHook.Provider value={exportableData}>
            {children}
        </AuthContextHook.Provider>
    )
}
