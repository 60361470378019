import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Logo from "../assets/LOGO.png";
import { Formik } from "formik";
import * as Yup from "yup";
import ButtonLoader from "../Utils/ButtonLoader";
import { Link, useNavigate } from "react-router-dom/dist";
import axios from "axios";
import { API_URLS } from "../Utils/API_URLS";

const Reset = () => {
  const [loader, setLoader] = useState(false);

  // const forgetPassword = async (values, formik) => {
  //   setLoader(true);
  //   let body = {
  //     oldPassword: values?.oldPassword,
  //     newPassword: values?.createPassword,
  //   };
  //   try {
  //     const { data } = await axios.post(API_URLS.forgotPassword, body, {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         Authorization: `Bearer ${localStorage.getItem("jwt")}`,
  //       },
  //     });
  //     console.log(data, "ddd");
  //     toast.success('Password Changed Successfully....');

  //     formik.resetForm();

  //   } catch (error) {
  //     console.log(error);
  //     toast.error(error?.response?.data?.message ? error?.response?.data?.message : "Some Error Occurred");
  //   }
  // };

  const forgetPassword = async (values, formik) => {
    try {
      setLoader(true);
      let body = {
        oldPassword: values?.oldPassword,
        newPassword: values?.createPassword,
      };

      const { data } = await axios.post(API_URLS.changePassword, body, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      // console.log(data, "ddd");
      toast.success('Password Changed Successfully....');

      formik.resetForm();
    } catch (error) {
      // console.log(error);
      toast.error(error?.response?.data?.message ? error?.response?.data?.message : "Some Error Occurred");
    } finally {
      // Set the loader to false after the API call is complete, whether it succeeds or fails.
      setLoader(false);
    }
  };

  return (
    <React.Fragment>
      <div className="dashboard">
        <div className="form-container1 py-5 px-4 d-flex">
          <Formik
            initialValues={{ oldPassword: "", createPassword: "" }}
            validationSchema={Yup.object({
                oldPassword: Yup.string().required(
                "Please Enter Correct Password"
              ),
              createPassword: Yup.string().required(
                "Please Enter Correct Password"
              ),
            })}
            onSubmit={(values, formik) => {
              // console.log(values);
              setLoader(true);
              forgetPassword(values, formik);
              
              setLoader(false);
            }}
          >
            {(formik) => (
              <form
                onSubmit={formik.handleSubmit}
                className="d-flex flex-column gap-3 step w-100"
              >
                <div className="form-header">
                  <h3>Reset Your Password</h3>
                </div>

                <div className="form-body d-flex flex-column gap-3">
                  <div className="d-flex flex-column">
                    <label htmlFor="oldPassword">Old Password</label>
                    <input
                      name="oldPassword"
                      type="text"
                      placeholder="Enter Your Udenz Mail"
                      onChange={formik.handleChange}
                      {...formik.getFieldProps("oldPassword")}
                      className="w-50"
                      id="input-border"
                    />
                    {formik.touched.oldPassword &&
                    formik.errors.oldPassword ? (
                      <div className="field-error">
                        {formik.errors.oldPassword}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="form-body d-flex flex-column gap-3">
                  <div className="d-flex flex-column">
                    <label htmlFor="createPassword">New Password</label>
                    <input
                      name="createPassword"
                      type="text"
                      placeholder="Enter Your Password"
                      onChange={formik.handleChange}
                      className="w-50"
                      id="input-border"
                      {...formik.getFieldProps("createPassword")}
                    />
                    {formik.touched.createPassword &&
                    formik.errors.createPassword ? (
                      <div className="field-error">
                        {formik.errors.createPassword}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="form-footer mt-3">
                  <div className="">
                    <button disabled={loader} type="submit" className="w-25">
                      {loader ? <ButtonLoader /> : "Send"}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Reset;
